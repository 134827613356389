<template>
  <secondary-overlay class="lg:px-60 px-5 pt-10 pb-20">
    <h2>Synopsis</h2>
    <p>
      A Decameron-esque tale of the human condition, "Through the Plexi-Glass: The Last Days of the San Jose" tells the
      story of a real estate deal's unexpected detour in the late nineties, one that ultimately sparked the
      gentrification of Austin’s hippest neighborhood. Through the lens of the camera that Liz Lambert carried by her
      side as the new owner of a run-down motel, we step into the universe of a drug-laden, often-dangerous place,
      impossibly full of heart, hope and humor. The film casts a light on human relationships, underlying gentrification
      and urban renewal, and lives inside layers of knotty, unanswerable questions, all of which are more poignant and
      pressing than ever today.
    </p>
    <img src="@/assets/images/about/liz.jpg" alt="Director: Liz Lambert" />
    <h2>Liz Lambert</h2>
    <p class="text-lg"><em>Director/Producer</em></p>
    <p>
      Liz Lambert, a former attorney turned hotelier, transformed a rundown motel on South Congress Avenue twenty-three
      years ago into the Hotel San José, the quintessential “Austin” hotel. Liz went on to found Bunkhouse Group,
      FARWEST, and Lambert McGuire Design, most recently joining MML Hospitality. Her new project, Hotel Saint Vincent,
      opens in New Orleans this Spring.
    </p>
    <p>
      Prior to her experience as a hotelier, Lambert worked as a prosecutor in the Manhattan District Attorney’s office
      before returning to Texas in 1994 to work in the Attorney General’s office in Austin. Currently, she also serves
      on the Board of Directors of the National Council on Crime & Delinquency.
    </p>

    <img src="@/assets/images/about/tina.jpg" alt="Director Bio: Tina Gazzerro Clapp" />
    <h2>Tina Gazzerro Clapp</h2>
    <p class="text-lg"><em>Director/Producer/Editor</em></p>
    <p>
      An Emmy-nominated producer, Tina Gazzerro Clapp has edited, directed and executive produced nonfiction television
      for over twenty years. Documentary storytelling is her true love, and this film has been a longtime passion
      project. She currently serves as the Executive Vice President of Programming for Evolution Media, an MGM company.
    </p>
    <img src="@/assets/images/about/ariel.jpg" alt="Director Bio: Tina Gazzerro Clapp" />
    <h2>Ariel Quintans</h2>
    <p class="text-lg"><em>Editor/Producer</em></p>
    <p>
      Ariel Quintans has worked as an editor and director of commercials and television for over 20 years. Based in
      Austin, Texas, he has won an Emmy for his work on a documentary-series for the Austin PBS affiliate, and a Cannes
      Lion for his advertising work for the United States Air Force. He is currently the Executive Editor and Creative
      Director at Beast Studios’ Austin office, an award-winning editorial house inspired by storytelling and
      innovation, specializing in editorial and visual FX for the advertising, digital and music industries.
    </p>
  </secondary-overlay>
</template>

<script>
import SecondaryOverlay from "../components/SecondaryOverlay.vue";
export default {
  components: { SecondaryOverlay },
  methods: {},
};
</script>
<style lang="scss" scoped>
h2 {
  padding-top: 0.75em;
}
img {
  display: block;
  margin: auto;
  padding-top: 4em;
}
</style>
